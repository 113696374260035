<template>
  <div class="login" @click="hide_del">
    <template v-if="canRtc">
      <div class="header">
        <div class="header-bg"></div>
        <div class="header-logo">Mirror</div>
        <div class="header-box">
          <div class="header-title">2022年清华附中高中入学民乐特长生专业测试</div>
          <div class="header-title-sl">远程监考系统</div>
        </div>
      </div>
      <div class="login-box">
        <div class="login-from">
          <div class="login-from-title">欢迎登录</div>
          <!-- <mu-text-field
            v-model="loginForm.exam_uuid"
            label="考试编号"
            @focus="show_Del(1)"
            @click.stop="show_Del(1)"
            :error-text="exam_uuid_error"
            label-float
          >
            <i
              v-if="loginForm.exam_uuid && show_exam_uuid"
              class="iconfont iconshanchu"
              @click="delExamUuid()"
            ></i>
          </mu-text-field> -->
          <!-- <mu-text-field
            v-model="loginForm.zkz_num"
            label="准考证"
            @focus="show_Del(2)"
            @click.stop="show_Del(2)"
            :error-text="zkz_num_error"
            label-float
          >
            <i
              v-if="loginForm.zkz_num && show_zkz_num"
              class="iconfont iconshanchu"
              @click="delZkzNum()"
            ></i>
          </mu-text-field> -->

          <mu-text-field
            v-model="loginForm.sfz_num"
            label="身份证号"
            @focus="show_Del(2)"
            @click.stop="show_Del(2)"
            :error-text="sfz_num_error"
            label-float
          >
            <i
              v-if="loginForm.sfz_num && show_sfz_num"
              class="iconfont iconshanchu"
              @click="delSfzNum()"
            ></i>
          </mu-text-field>

           <mu-text-field
            readonly
            clickable
            v-model="loginForm.exam_name"
            label="场次"
            @focus="show_Del(1)"
            @click.stop="show_Del(1)"
            :error-text="exam_uuid_error"
            label-float
            :value="value"
          >
            <i
              v-if="loginForm.exam_uuid && show_exam_uuid"
              class="iconfont iconshanchu"
              @click="delExamUuid()"
            ></i>
          </mu-text-field>

          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                :default-index="0"
                @cancel="showPicker = false"
                @change="onChange"
                @confirm="onClassConfirm"
                value-key="subjectName"
            />
            </van-popup>

          <van-button
            :loading="loginLoading"
            loading-text="登录中..."
            @click="loginAction()"
            block
            color="linear-gradient(to right, #563279, #8b2d87)"
            >登 录
          </van-button>
        </div>
      </div>
    </template>
    <van-overlay v-if="!canRtc" show>
      <div class="no-canRtc">
        <p v-text="errorMsg"></p>
        <p>请使用自带的浏览器(Safari或者Chrome)打开</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { authMixin } from "mixins/index/login";

export default {
  name: "Login",
  mixins: [authMixin],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.login {
  background: #f8f8f8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .no-canRtc {
    text-align: center;
    font-size: 18px;
    color: #f8f8f8;

    p {
      &:first-child {
        margin-top: 50px;
        margin-bottom: 20px;
      }
    }
  }

  .header {
    position: relative;
    height: 200px;
    overflow: hidden;

    .header-bg {
      // width: 100%;
      // height: 150px;
      // background: linear-gradient(#563279, #8b2d87);
      // border-radius: 20px;
      // transform: rotate(28deg);
      // margin-top: -80px;
      // margin-right: -10px;

      width: 150%;
      height: 300px;
      background: linear-gradient(#563279, #8b2d87);
      transform: rotate(30deg);
      position: absolute;
      border-radius: 16px;
      top: -300px;
      transform-origin: bottom left;
    }
    .header-logo {
      position: absolute;
      height: 36px;
      font-size: 35px;
      font-family: Times New Roman;
      font-weight: bold;
      color: #fafafa;
      right: 25px;
      bottom: 100px;
    }

    .header-box{
      background-color: #f8f8f8;
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
    }
    .header-title {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }

    .header-title-sl {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }

  .login-box {
    clear: both;
    position: relative;
    height: 200px;
    margin-top: 15px;

    .login-from {
      .login-from-title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        letter-spacing: 1px;
        color: #333;
        margin-bottom: 10px;
      }

      width: 95%;
      box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      margin: 0 auto;
      height: 310px;
      background: #fff;
      padding: 15px;

      > .mu-input {
        width: 100%;
      }

      .mu-text-field {
      }
    }
  }
}
</style>
<style lang="scss">
.mu-input__focus {
  color: #8b2d87;
}

.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #8b2d87;
}
</style>
