//权限相关
import 'aliyun-webrtc-sdk'
import {login} from "r/index/login";
import {getSubject} from "r/index/getSubject";
import {studentStatus} from "r/index/studentStatus";
import {Dialog, Toast,Overlay } from 'vant';
export const authMixin = {
  data() {
    return {
      loginForm: {
        exam_uuid: "",
        exam_name:"",
        zkz_num: "",
        sfz_num: ""
      },
      canRtc:true,
      show_exam_uuid:false,
      loginLoading:false,
      exam_uuid_error:'',
      zkz_num_error:'',
      errorMsg:'',
      show_zkz_num:false,
      sfz_num_error:'',
      show_sfz_num:false,
      columns:[],
      showPicker:false,
      value:''
    };
  },
  created() {
    let aliWebrtc = new AliRtcEngine();
    /**
     * AliWebRTC isSupport检测{isReceiveOnly: true}
     */
    const option = {isReceiveOnly: true};
    aliWebrtc.isSupport(option).then(re => {
      this.canRtc = true;
    }).catch(error => {
      this.errorMsg = error.message;
      this.canRtc = false;
    })
    localStorage.setItem("studentStatusTime",0);
    this.getSubject();
  },
  methods: {
    onClassConfirm(v) {
      this.classId = v.subjectUuid;
      this.className = v.subjectName;
      this.showPicker = false;
    },
    onChange(value, index) {
      if(index.subjectUuid != "000"){
        this.loginForm.exam_uuid = index.subjectUuid;
        this.loginForm.exam_name = index.subjectName;
      }else{
        this.loginForm.exam_uuid = "";
        this.loginForm.exam_name = "";
      }
      // Toast(`当前值: ${value}, 当前索引: ${index}`);
    },
    onCancel(){
      this.loginForm.exam_uuid = "";
      this.loginForm.exam_name = "";
    },
    delExamUuid(){
      this.loginForm.exam_uuid = '';
    },
    delZkzNum(){
      this.loginForm.zkz_num = '';
    },
    delSfzNum(){
      this.loginForm.sfz_num = '';
    },
    hide_del(){
      this.show_exam_uuid = false;
      // this.show_zkz_num = false;
       this.show_sfz_num = false;
    },
    async getSubject(){
      let {data} = await getSubject();
      if(!data){
        Toast('数据异常');
      }else{
        data.unshift({"subjectName" : "请选择","subjectUuid" : "000"})
        this.columns = data;
      }
    },
    show_Del(type){
      if(type === 1){
        this.show_exam_uuid = true;
        this.show_sfz_num = false;
        this.exam_uuid_error = '';
        this.showPicker = true;
        this.checkSfzNum();
      }else {
        this.show_sfz_num = true;
        this.show_exam_uuid = false;
        this.sfz_num_error = '';
        this.checkExamUuid();
      }
    },
    async loginAction() {
      let dataForm = {};
      let exam_uuid = this.checkExamUuid();

      if(!exam_uuid){
        return false;
      }
      let sfzNum = this.checkSfzNum();
      if(!sfzNum){
        return false;
      }

      if (
        sfzNum.slice(17, 18) == "x" ||
        sfzNum.slice(17, 18) == "X"
      ) {
        sfzNum = sfzNum.slice(0, 17) + "X";
      }

      dataForm.subjectUuId = exam_uuid;
      dataForm.zkzNum = sfzNum;
      this.loginLoading = true;
      let {data} = await login(dataForm);
      if(data.code == 200 && data.data.token){
        data = data.data;
        localStorage.setItem("monitor-tokenExpire", data.tokenExpire+'');
        this.$store.commit("login", data.tokenHead+' '+data.token);
        localStorage.setItem("monitor-subjectUuId", exam_uuid);
        localStorage.setItem("monitor-examName", this.loginForm.exam_name);
        localStorage.setItem("monitor-zkz", sfzNum);
        localStorage.setItem("monitor-sfz", sfzNum);
        localStorage.setItem("monitor-name", data.name);
        localStorage.setItem("monitor-videoSize", data.videoSize);
        localStorage.setItem("monitor-isVerify", data.isVerify);
        // this.studentStatusPost(sfzNum,exam_uuid);
        this.loginLoading = false;
        await this.$router.replace('/position');
      //   console.log(data.isVerify == 1)
      //   if(data.isVerify == 1) {
      //     // 进入人脸识别页面
      //     await this.$router.push({path: '/camera'});
      //     return ;
      //   }
      //   if(data.videoSize == 1){
      //     localStorage.setItem("monitor-camera",'front');
      //     await this.$router.push({path: '/monitor'});
      //     return ;
      //   }
      //   let camera = localStorage.getItem("monitor-camera");
      //   if(camera){
      //     await this.$router.push({path: '/monitor'});
      //   }else {
      //     await this.$router.push({path: '/position'});
      //   }
      }else {
        this.loginLoading = false;
        Dialog({ message: data.data.message });
      }
    },
     studentStatusPost(sfzNum,exam_uuid){
        var time1 = Date.parse(new Date());
        let studentStatusTime = localStorage.getItem("studentStatusTime");
        if( time1 > parseInt(studentStatusTime) + 10){
            studentStatus(sfzNum,exam_uuid).then( (res) => {
              localStorage.setItem("monitor-huanjing", res.data.huanjing);
              localStorage.setItem("monitor-renzheng", res.data.renzheng);
              localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
              localStorage.setItem("studentStatusTime", time1);
            });
        }
    },
    checkExamUuid(){
      let exam_uuid = this.loginForm.exam_uuid.trim();
      if(!exam_uuid){
        this.exam_uuid_error = '请选择场次';
        return false;
      }
      // if(exam_uuid.length !== 6){
      //   this.exam_uuid_error = '请输入6位的考试编号';
      //   return false;
      // }
      return exam_uuid;
    },
    checkZkzNum(){
      let zkz_num = this.loginForm.zkz_num.trim();
      if(!zkz_num){
        this.zkz_num_error = '请输入准考证号';
        return false;
      }
      // if(zkz_num.length !== 6){
      //   this.zkz_num_error = '请输入6位的考试编号';
      //   return false;
      // }
      return zkz_num;
    },
    checkSfzNum(){
      let sfz_num = this.loginForm.sfz_num.trim();
      if(!sfz_num){
        this.sfz_num_error = '请输入身份证号';
        return false;
      }
      // if(zkz_num.length !== 6){
      //   this.zkz_num_error = '请输入6位的考试编号';
      //   return false;
      // }
      return sfz_num;
    }
  },
  activated() {

  },
};
